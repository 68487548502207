import Vue from 'vue/dist/vue.esm.js';
import BootstrapVue from 'bootstrap-vue';

Vue.config.devtools = true

Vue.use(BootstrapVue);

Vue.prototype.$msg = function(key, d) {
    if(d == null) {
        d = key
    }
    return I18N.messages[key] || d
};

var VueTools = {

    markAll: function(selector) {
        var selectedRows = $(selector + ' .selected');
        $.each(selectedRows, function(idx, rowSelector) {
            $(rowSelector).iCheck('check');
        });
    },
    unmarkAll: function(selector) {
        var selectedRows = $(selector + ' .selected');
        $.each(selectedRows, function(idx, rowSelector) {
            $(rowSelector).iCheck('uncheck');
        });
    },
    invert: function(selector) {
        var selectedRows = $(selector + ' .selected');
        $.each(selectedRows, function(idx, rowSelector) {
            $(rowSelector).iCheck('toggle');
        });
    },
    clicked: function(selector) {
        var selectedRows = $(selector + " .selected")

        var checked = 0
        var unchecked = 0
        $.each(selectedRows, function(idx, rowSelector) {
            if(rowSelector.checked) {
                checked = checked + 1
            } else {
                unchecked = unchecked + 1
            }
        });

        $.each($(selector + " .titleselect"), function(idx, rowSelector) {
            if(checked > 0 && unchecked > 0) {
                $(rowSelector).iCheck('indeterminate')
            } else if(checked === 0 && unchecked > 0) {
                $(rowSelector).iCheck('uncheck').iCheck('update');
            } else if(checked > 0 && unchecked === 0) {
                $(rowSelector).iCheck('check');
            }
        });
    },
    headerclicked: function(selector) {
        if($(selector + " .titleselect")[0].checked) {
            VueTools.markAll(selector)
        } else {
            VueTools.unmarkAll(selector)
        }
    }
};

window.Vue = Vue;
window.VueTools = VueTools;